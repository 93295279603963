<template>
  <base-layout>
    <!--header-bar></header-bar-->

    <div class="col-12 scanlist-banner"> 
      <font-awesome-icon class="me-1" :icon="['fal', 'qrcode']"/>
        {{ wordings[currentLanguage].SCANS_TITLE }}
    </div>

    <ion-content >
        <div class="accordion" id="accordionExampleFav">
        <div class="accordion-item" v-if="scannedSessionList.length > 0">
          <h2 class="accordion-header" id="sessionHeadingFav">
            <button class=" position-relative accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseSessionsFav" aria-expanded="false" aria-controls="collapseSessionsFav">
              {{ wordings[currentLanguage].SECTION_SESSION }}
              <span style="z-index: 2000;top:25px;right:30px"
                    class="position-absolute top-0 end translate-middle badge rounded-pill bg-primary">
                {{ scannedSessionList.length }}
              </span>
            </button>
          </h2>
          <div id="collapseSessionsFav" class="accordion-collapse collapse" aria-labelledby="sessionHeadingFav"
               data-bs-parent="#accordionExampleFav">
            <div style="padding-top:10px; padding-bottom:10px">
               <div v-for="session in scannedSessionList" :key="session.id">
              <session-item :session="session" v-if="session"></session-item>
            </div>
            </div>
          </div>
        </div>
        </div>
    </ion-content>

    <div v-if="isApp" class="qr-button" @click="scanCode()"> <font-awesome-icon style="position: absolute; font-size:30px; left: 14px; top:9px"  :icon="['fal', 'plus']"/>  <font-awesome-icon style="position: absolute; font-size:10px; right: 8px; top:28px" class="me-1" :icon="['fal', 'qrcode']"/> </div>

     <div class="row ps-0 ps-md-2 mt-2 scan-controls">
          <button class="btn btn-light" type="button" @click="stopScan()">
            <font-awesome-icon class="me-1" :icon="['fal', 'qrcode']"/>
            HIDE QR
          </button>
    </div>

  </base-layout>
</template>

<script>
import {IonContent} from '@ionic/vue';
import {defineComponent} from 'vue';
import {menuController} from "@ionic/vue";
import {mapActions, mapState} from 'vuex';
import {gridOutline, chevronForward, chevronBack} from 'ionicons/icons';
import SessionItem from "@/components/SessionItem";
import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';
//moment.locale("de");

const config = require('@/config')

export default defineComponent({
  name: 'ScanList',
  data() {
    return {
      scansessions: [],
      search: "",
      filtered: [],
      searchDone: false,
      isApp : config.is_app,
      scannedSessionList : [],
    }
  },

  setup() {
    return {
      gridOutline,
      chevronForward,
      chevronBack
    }
  },
  components: {
    IonContent,
    'session-item': SessionItem,
  },
  computed: {
    ...mapState('sync', ['updateFinished']),
    ...mapState('wordings', ['wordings', 'currentLanguage']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion', 'primaryColor', 'menuheader']),
     scannedItems: function(){
      return JSON.parse(localStorage.getItem('scannedItems') || '[]');
    }
  },


  methods: {
    ...mapActions('googleanalytics', ['trackWithLabel']),
     ...mapActions('sessions', ['getSessionListLive']),

    
    openMenu() {
      menuController.open("app-menu")
    },
    
     async didUserGrantPermission() {
      // check if user already granted permission
      const status = await BarcodeScanner.checkPermission({force: false});

      if (status.granted) {
        // user granted permission
        return true;
      }

      if (status.denied) {
        // user denied permission
        const c = confirm('If you want to grant permission for using your camera, enable it in the app settings.');
        if (c) {
          BarcodeScanner.openAppSettings();
        }
      }

      if (status.asked) {
        // system requested the user for permission during this call
        // only possible when force set to true
      }

      if (status.neverAsked) {
        // user has not been requested this permission before
        // it is advised to show the user some sort of prompt
        // this way you will not waste your only chance to ask for the permission
        const c = confirm('We need your permission to use your camera to be able to scan barcodes');
        if (!c) {
          return false;
        }
      }

      if (status.restricted || status.unknown) {
        // ios only
        // probably means the permission has been denied
        return false;
      }

      // user has not denied permission
      // but the user also has not yet granted the permission
      // so request it
      const statusRequest = await BarcodeScanner.checkPermission({force: true});

      if (statusRequest.asked) {
        // system requested the user for permission during this call
        // only possible when force set to true
      }

      if (statusRequest.granted) {
        // the user did grant the permission now
        return true;
      }

      // user did not grant the permission, so he must have declined the request
      return false;
    },

    async scanCode() {
        let permission = await this.didUserGrantPermission();

        if (permission) {
          await BarcodeScanner.hideBackground();
          document.querySelector('body').classList.add('scanner-active');
          document.body.style.background = "transparent";

          console.log('scan', permission)
          const result = await BarcodeScanner.startScan({targetedFormats: [SupportedFormat.QR_CODE]}); // start scanning and wait for a result
          console.log('scan res', result)
          if (result.hasContent) {
            document.querySelector('body').classList.remove('scanner-active');
            let isExternal = new RegExp("^(http|https)://").test(result.content);
            let cleanedContent = isExternal ? result.content.split('/').pop() : result.content
            this.code = cleanedContent.split('/').pop();
        
            
               let scannedItems = JSON.parse(localStorage.getItem('scannedItems')) || {sessions: [], exhibitors: []};
               if(scannedItems.sessions.indexOf(this.code) == -1){ //SESSIONS
                  scannedItems.sessions.push(this.code)
               }
                
               localStorage.setItem('scannedItems', JSON.stringify(scannedItems));

              this.$root.$emit('updateScannedItems') 

              this.fetchSessions();
          }
        }
    },
    stopScan() {
      BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();
      document.querySelector('body').classList.remove('scanner-active');

    },
    
 
     async fetchSessions() {
      this.scannedSessionList = [];
     this.scansessions = [];
     this.sessions = (await this.getSessionListLive()).sort((a, b) => {
        return a.location_order - b.location_order;
      });

      let scannedItems = JSON.parse(localStorage.getItem('scannedItems'))
      let scannedSessions = [];

      if(scannedItems != null && scannedItems.sessions){
       scannedSessions = scannedItems.sessions;
      } 

      for(let i =0; i < scannedSessions.length;i++){
        var result = this.sessions.find(obj => {
        return obj.id === scannedSessions[i];
        })
        if(result!=undefined){
          this.scannedSessionList.push(result)
        }
      }

    },
  },
  async created() {
   // this.fetchSessions();
    //this.activeDay = this.$route.params.day;
    

  },

  
  watch: {
    async handler(to) {
        if(to && to.name!="ScanList"){
            this.stopScan();
        }
      },

     updateFinished: async function (newVal) {
      if (newVal) {
        this.fetchSessions()
      }
      
    },
    
   '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(to) {

        if (to.name == 'ScanList') {
          this.fetchSessions();
        } 
        
      }
    },
  },

});
</script>
<style lang="scss">



  .punkt-selected {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}
.large-selected{
font-weight: 900;
}
.label-wrapper {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 15px;

  .label-badge {
    font-size: 0.7rem;
    background: #fff;
    color: black;
    border-radius: 25px;
    padding: 0 5px;
  }
}
.small-badge{
  position: absolute;
  top:0;
  right:10px;
  font-size: 0.8rem;
  background: #fff;
  color: black;
  border-radius: 25px;
  padding: 0 5px;
}

.qr-button{
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #007893;
  color: white;
  text-align: center;
  overflow: hidden;
  bottom: 20px;
  right:20px;
}


.scan-controls{
  display: none;
}

</style>
